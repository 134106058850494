export class Member {
  public first_name!: string
  public last_name!: string
  public first_name_kana!: string
  public last_name_kana!: string
  public email!: string
  public email_confirmation!: string
  public password!: string
  public password_confirmation!: string
  public phone_no !: string | null
  public gender !: number
  public address?: string = ''
  public apartment_no!: string
  public birthday!: string | null
  public building!: string
  public city?: string = ''
  public prefecture?: string = ''
  public delivery_time_zone!: string
  public timezone!: string
  public with_regular_delivery!: string
  public zip_code!: string


  constructor(init?: Member) {
    Object.assign(this, init)
  }
}
