import Const from '../constants'
import { Member } from '../models/Member'
import axiosApiInstance from '../interceptors'

class MembershipInformationService {
  getMember() {
    return axiosApiInstance.get(Const.api_url + 'member/profile')
  }

  updateProfile(data: Member) {
    let profile: any = {
      first_name: data.first_name,
      last_name: data.last_name,
      first_name_kana: data.first_name_kana,
      last_name_kana: data.last_name_kana,
      phone_no: data.phone_no,
      zip_code: data.zip_code,
      prefecture: data.prefecture,
      city: data.city,
      address: data.address,
      apartment_no: data.apartment_no,
      delivery_time_zone: data.delivery_time_zone,
    }
    if (data.birthday && data.birthday !== 'Invalid date') {
      profile.birthday = data.birthday
    }
    if (data.gender) {
      profile.gender = data.gender
    }
    return axiosApiInstance.put(Const.api_url + 'member/profile', {
      email: data.email,
      profile: profile
    })
  }

  async addressReflected(zip_code: any) {
    return await axiosApiInstance.get(Const.api_url + 'zipcode/info?zip_code=' + zip_code)
  }
}

export default new MembershipInformationService()
